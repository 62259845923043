import styled from 'styled-components'
import colours from './colours'
import { shade } from 'polished'

export const ScaleH1 = styled.h1`
  @media screen and (min-width: 1800px) {
    font-size: 2vw;
  }
  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`

export const NumberSpan = styled.span`
  font-size: 2.5em;
`

export const ImgOverlay = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 5vw;
  letter-spacing: -1px;
  @media screen and (min-width: 1800px) {
    font-size: 4vw;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.65rem;
  }

  span {
    background: rgb(0, 0, 0);
    background: rgb(0, 0, 0, 0.5);
    padding: 1.2vw 2.8vw 1.2vw 2.8vw;
  }
`

export const Email = styled.a`
  .abbrev {
    display: none;
  }
  .full {
    display: inline;
  }
  @media (max-width: ${props => props.breakPoint}) {
    .abbrev {
      display: inline;
    }
    .full {
      display: none;
    }
  }
`

export const FlagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`

export const Flag = styled.span`
  font-size: 3.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const Price = styled.p`
  text-align: center;
  font-size: 1.7rem;
  span {
    font-weight: 700;
    font-style: italic;
    font-size: 2em;
  }
`

export const Pricing = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 1.5rem 0 0 0;
  .hrOnSmall {
    display: none;
  }
  @media (max-width: 780px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    .hrOnSmall {
      display: block;
    }
  }
  ul {
    padding: 0 0 0 1rem;
  }
`

export const DetailsLinkBox = styled.div`
  margin: 2rem 1rem 2rem 1rem;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: 0px 2px 5px 1px rgb(0.1, 0.1, 0.1, 0.5);
  background-color: ${shade(0.2, colours.primary)};
  border-radius: 1rem;
  line-height: 1.55;
  p {
    margin: 0.75rem 0 0.75rem 0;
  }
`

export const ContactDetail = styled.p`
  font-size: 1.5rem;
  padding: 1rem 0 0.5rem 0 !important;
  text-align: center;
  margin: 0 !important;
  svg {
    margin: 0.5rem 0 0.75rem 0;
  }
`

export const FancyHr = styled.hr`
  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(
    radial,
    50% 50%,
    0,
    50% 50%,
    350,
    from(#fff),
    to(#1a1f16)
  );
`
