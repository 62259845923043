import styled from 'styled-components'
import colours from './colours'
import { desaturate, complement } from 'polished'

export const ToursWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.5rem;
`

export const TourWrapper = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  font-size: 1.1rem;
  min-width: 250px;
  background-color: ${colours.darkPrimary};
  border: 2px solid ${complement(colours.darkPrimary)};
  box-shadow: 0px 5px 5px 1px rgb(0.1, 0.1, 0.1, 0.5);
  border-radius: 14px;
  padding: 0.5rem;
  margin: 0.5rem;
  h4,
  p {
    margin: 0.2rem 0 0.2rem 0;
  }
`

export const IconBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

export const IconWrapper = styled.span`
  font-size: 1.5rem;
`

export const SoldOutBanner = styled.span`
  position: absolute;
  left: 2rem;
  right: 2rem;
  top: 55%;
  padding: 0.5rem;
  transform: rotate(-10deg);
  font-size: 1.25rem;
  transform: rotate(45);
  background-color: ${desaturate(0.5, '#ff0000')};
  border: 2px solid ${desaturate(0.75, '#ff0000')};
  text-transform: uppercase;
`
