import React from 'react'
import { graphql, Link, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import { FaMotorcycle, FaEnvelope, FaPhone } from 'react-icons/fa'

import Layout from '../components/layout'
import Row from '../components/row'
import SEO from '../components/seo'
import TourBlock from '../components/tourBlock'

import {
  ScaleH1,
  NumberSpan,
  ImgOverlay,
  Email,
  FlagWrapper,
  Flag,
  Price,
  Pricing,
  DetailsLinkBox,
  ContactDetail,
  FancyHr
} from '../styledComponents/home'

import {
  PadDiv,
  Centre,
  ImgContainer,
  ImgWrapper
} from '../styledComponents/shared'

import 'flag-icon-css/css/flag-icon.min.css'

const IndexPage = ({ data }) => {
  const tours = data.allToursJson.nodes[0].tour

  return (
    <Layout>
      <SEO url='/' />
      <Row>
        <ImgContainer maxWidth={data.site.siteMetadata.maxWidth}>
          <ImgWrapper maxHeight='850px'>
            <Img fluid={data.heroImage.childImageSharp.fluid} />
            <ImgOverlay>
              <span>Great War</span>
              <span>Bike Tours</span>
            </ImgOverlay>
          </ImgWrapper>
        </ImgContainer>
      </Row>
      <Row bgColour='var(--darkPrimary)' fontColour='var(--secondary)'>
        <div
          style={{
            backgroundImage: `url(${withPrefix('/images/poppies.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: '2200px'
          }}
        >
          <PadDiv>
            <Centre>
              <FaMotorcycle
                style={{ fontSize: '4rem', marginBottom: '1rem' }}
              />
              <ScaleH1
                style={{
                  textAlign: 'center',
                  lineHeight: '1.5',
                  padding: '1rem',
                  background: 'rgba(1, 1, 1, 0.75)',
                  maxWidth: '70%'
                }}
              >
                Join {data.site.siteMetadata.title} on a motorcycling tour of
                remembrance.
              </ScaleH1>
              <FancyHr style={{ width: '60%' }} />
              <ScaleH1
                style={{
                  textAlign: 'center',
                  lineHeight: '1.5',
                  padding: '1rem',
                  background: 'rgba(1, 1, 1, 0.75)',
                  maxWidth: '70%'
                }}
              >
                Ride on some of Europe's most stunning roads with beautiful
                scenery that were surely built with motorcycles in mind.
              </ScaleH1>
              <FancyHr style={{ width: '60%' }} />
              <ScaleH1
                style={{
                  textAlign: 'center',
                  lineHeight: '1.5',
                  padding: '1rem',
                  background: 'rgba(1, 1, 1, 0.75)',
                  maxWidth: '70%'
                }}
              >
                Step back in time and visit some of the most important
                battlefields, incredible museums and memorials to the fallen of
                the great war.
              </ScaleH1>
              <FancyHr style={{ width: '60%' }} />
              <FlagWrapper>
                <Flag className='flag-icon flag-icon-gb' />
                <Flag className='flag-icon flag-icon-fr' />
                <Flag className='flag-icon flag-icon-be' />
              </FlagWrapper>
            </Centre>
          </PadDiv>
        </div>
      </Row>
      <Row bgColour='var(--primary)' fontColour='var(--secondary)'>
        <Centre>
          <Pricing>
            <div>
              <h1>Simple pricing</h1>
              <p style={{ fontSize: '1.3rem', maxWidth: '350px' }}>
                <NumberSpan>1</NumberSpan>st Thursday of every month, then again
                2 weeks later
              </p>
              <p style={{ fontSize: '1.3rem' }}>
                <NumberSpan>2</NumberSpan> countries
              </p>
              <p style={{ fontSize: '1.3rem' }}>
                <NumberSpan>3</NumberSpan> hotel nights
              </p>
              <p style={{ fontSize: '1.3rem' }}>
                <NumberSpan>4</NumberSpan> days
              </p>
            </div>
            <div>
              <hr
                style={{
                  margin: '1.5rem auto 1.5rem',
                  width: '80%',
                  height: '10px'
                }}
                className='hrOnSmall'
              />
              <Price>
                Only{' '}
                <span>
                  &pound;{(data.site.siteMetadata.tourPrice / 100).toFixed(2)}
                </span>{' '}
                p.p.
              </Price>
              <Price style={{ fontSize: '1rem' }}>
                (Pillion{' '}
                <span style={{ fontSize: '1.1rem', marginTop: 0 }}>
                  &pound;
                  {(data.site.siteMetadata.tourPillionPrice / 100).toFixed(2)}
                </span>{' '}
                p.p.)
              </Price>
              <hr
                style={{
                  margin: '1.5rem auto 1.5rem',
                  width: '80%',
                  height: '10px'
                }}
              />
              <div
                style={{
                  fontWeight: 200,
                  maxWidth: '500px',
                  fontSize: '1.2rem'
                }}
              >
                <p>Price includes:</p>
                <ul>
                  <li>EuroStar return ticket </li>
                  <li>Bike secure accommodation</li>
                  <li>Breakfast</li>
                  <li>Museum entrance fees</li>
                  <li>Tour guide</li>
                </ul>
              </div>
              <hr
                style={{
                  margin: '1.5rem auto 1.5rem',
                  width: '80%',
                  height: '10px'
                }}
              />
              <div style={{ fontWeight: 200, maxWidth: '500px' }}>
                <p style={{ fontStyle: 'italic', margin: '0' }}>
                  Price excludes, petrol, any bike costs and any meals/food
                  besides breakfast.
                </p>
              </div>
            </div>
          </Pricing>
          <DetailsLinkBox>
            <p>
              <Link to='/tour-sites'>More about where we visit</Link> and{' '}
              <Link to='/tour-details'>
                see our tour availability and important details
              </Link>
            </p>
          </DetailsLinkBox>
        </Centre>
      </Row>
      <Row>
        <ImgContainer maxWidth={data.site.siteMetadata.maxWidth}>
          <ImgWrapper maxHeight='850px'>
            <Img fluid={data.roadRowImage.childImageSharp.fluid} />
          </ImgWrapper>
        </ImgContainer>
      </Row>
      <Row bgColour='var(--primary)'>
        <PadDiv>
          <Centre>
            <h1 style={{ marginBottom: '0.2rem' }}>Book today</h1>
            <ContactDetail>
              <FaEnvelope />
              <br />
              <Email
                href={`mailto:${data.site.siteMetadata.email}`}
                breakPoint='600px'
              >
                <span className='abbrev'>Email</span>
                <span className='full'>{data.site.siteMetadata.email}</span>
              </Email>
            </ContactDetail>
            <ContactDetail>
              <FaPhone />
              <br />
              <a href={`tel:+44${data.site.siteMetadata.phone.slice(1)}`}>
                {data.site.siteMetadata.phone}
              </a>
            </ContactDetail>
            <h2
              style={{
                textAlign: 'center',
                marginTop: '2rem',
                marginBottom: '0.25rem'
              }}
            >
              Next tour departs:
            </h2>
            <TourBlock tours={tours} dropPast nextTour />
            <Link to='/tour-details'>See more tour dates!</Link>
          </Centre>
        </PadDiv>
      </Row>
      <Row>
        <ImgContainer maxWidth={data.site.siteMetadata.maxWidth}>
          <ImgWrapper maxHeight='600px'>
            <Img fluid={data.beachRow.childImageSharp.fluid} />
          </ImgWrapper>
        </ImgContainer>
      </Row>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        tourPrice
        tourPillionPrice
        email
        phone
        maxWidth
      }
    }
    heroImage: file(relativePath: { eq: "motorbike_side.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    roadRowImage: file(relativePath: { eq: "road1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beachRow: file(relativePath: { eq: "beach1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allToursJson {
      nodes {
        tour {
          date
          spaces
          booked
        }
      }
    }
  }
`
