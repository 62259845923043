import React from 'react'
import Tour from './tour'
import moment from 'moment'

import { ToursWrapper } from '../styledComponents/tour'

const TourBlock = ({ tours, dropPast, nextTour }) => {
  tours.forEach(tour => {
    tour.date = moment.utc(tour.date)
    return tour
  })

  tours.sort((a, b) => {
    return a.date.valueOf() - b.date.valueOf()
  })

  if (dropPast) {
    tours = tours.filter(tour => {
      return tour.date > moment.utc()
    })
  }

  if (nextTour) {
    tours = [tours[0]]
  }

  return (
    <ToursWrapper>
      {tours.map((tour, index) => {
        return <Tour tour={tour} key={index} nextTour={nextTour} />
      })}
    </ToursWrapper>
  )
}

export default TourBlock
