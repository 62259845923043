import React from 'react'
import { range } from 'lodash'
import { FaMotorcycle } from 'react-icons/fa'
import colours from '../styledComponents/colours'
import {
  TourWrapper,
  IconBlock,
  IconWrapper,
  SoldOutBanner
} from '../styledComponents/tour'
import { darken } from 'polished'

const Tour = ({ tour, nextTour }) => {
  const activeBikes = tour.spaces - (tour.booked ? tour.booked : 0)
  const nonActiveBikes = tour.spaces - activeBikes

  return (
    <TourWrapper>
      {!nextTour && <h4>Tour departing:</h4>}
      <p>{tour.date.format('dddd Do MMMM, YYYY')}</p>
      <h4>Spaces remaining:</h4>
      <p>{activeBikes}</p>
      <IconBlock>
        {range(activeBikes).map(index => {
          return (
            <IconWrapper key={index}>
              <FaMotorcycle />
            </IconWrapper>
          )
        })}
        {range(nonActiveBikes).map(index => {
          return (
            <IconWrapper key={index}>
              <FaMotorcycle
                style={{ color: darken(0.75, colours.secondary) }}
              />
            </IconWrapper>
          )
        })}
      </IconBlock>
      {activeBikes === 0 && <SoldOutBanner>Sold out</SoldOutBanner>}
    </TourWrapper>
  )
}

export default Tour
